import React, {
  useEffect,
  useRef,
  useState,
  MutableRefObject,
  useCallback,
} from "react";
import ReactLoading from "react-loading";
import { useQuery } from "../../../utils/get";
import PlayerControl from "../../PlayerControl";
import { HearingService } from "../../../services/hearing";

import {
  Container,
  VideoContainer,
  VideoPlayerComponent,
  LoadingContainer,
  VideoText,
} from "./styles";

import {
  handleActionBackTime,
  handleActionNextTime,
  handleAddShortCuts,
  handleFastSpeed,
  handleSlowSpeed,
  shortcut,
} from "./constants";
import { IVideoPlayer } from "./interface";
import { getValueFromlocalStorage } from "../../../utils/sessionStorageEncrypt";

function usePlayerState($videoPlayer: MutableRefObject<HTMLVideoElement>) {
  const [playerState, setPlayerState] = useState({
    playing: false,
    progress: 0,
    currentTime: "00:00",
    duration: "00:00",
    secondsCurrentTime: 0,
    secondsDuration: 0,
    volume: 1,
    playbackRate: 1,
    marking: null,
  });

  function toggleVideoPlay() {
    setPlayerState({
      ...playerState,
      playing: !playerState.playing,
    });

    if ($videoPlayer.current && playerState.playing) {
      $videoPlayer.current.pause();
    } else {
      $videoPlayer.current.play();
    }
  }

  function toggleVideoPause() {
    setPlayerState({
      ...playerState,
      playing: false,
    });

    if ($videoPlayer.current) {
      $videoPlayer.current.pause();
    }
  }

  function toggleVideoProgress(currentTime: number, percent: number) {
    const totalSeconds = $videoPlayer.current.duration;
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = Math.floor(totalSeconds % 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    const totalSecondsCurrent = currentTime;
    const totalMinutesCurrent = Math.floor(totalSecondsCurrent / 60);

    const secondsCurrent = Math.floor(totalSecondsCurrent % 60);
    const minutesCurrent = totalMinutesCurrent % 60;
    const hoursCurrent = Math.floor(totalMinutesCurrent / 60);

    setPlayerState({
      ...playerState,
      progress: percent,
      secondsCurrentTime: currentTime - 0.05,
      secondsDuration: totalSeconds,
      duration: `${hours === 0 ? "" : hours < 10 ? `0${hours}:` : `${hours}:`}${minutes < 10 ? "0" + minutes : minutes
        }:${seconds < 10 ? "0" + seconds : seconds}`,
      currentTime: `${hoursCurrent === 0
        ? ""
        : hoursCurrent < 10
          ? `0${hoursCurrent}:`
          : `${hoursCurrent}:`
        }${minutesCurrent < 10 ? "0" + minutesCurrent : minutesCurrent}:${secondsCurrent < 10 ? "0" + secondsCurrent : secondsCurrent
        }`,
    });

    if (percent === 100 && playerState.playing) {
      toggleVideoPlay();
      if ($videoPlayer.current) {
        $videoPlayer.current.currentTime = 0;
      }
    }
  }

  function toggleFullscreen() {
    $videoPlayer.current.requestFullscreen();
  }

  function toggleChangeProgress(percent: number) {
    const currentTime = ($videoPlayer.current.duration / 100) * percent;
    if ($videoPlayer.current)
      $videoPlayer.current.currentTime = currentTime;

    setPlayerState({
      ...playerState,
      secondsCurrentTime: currentTime,
    });
  }

  function toggleFiveSeconds(type: "advance" | "back") {
    let timeDefault: number = 5;
    const timeConfig = getValueFromlocalStorage("GlobalTimeBackNext");

    if (timeConfig !== null) {
      timeDefault = parseInt(timeConfig);
    }

    switch (type) {
      case "advance":
        if ($videoPlayer.current)
          $videoPlayer.current.currentTime += timeDefault;
        break;
      case "back":
        if ($videoPlayer.current)
          $videoPlayer.current.currentTime -= timeDefault;
        break;
    }
  }

  function toggleVolume(volume: number) {
    setPlayerState({ ...playerState, volume });
    $videoPlayer.current.volume = volume;
  }

  function toggleRate(rate: number) {
    $videoPlayer.current.playbackRate = rate;
    setPlayerState({ ...playerState, playbackRate: rate });
  }

  function toggleMarkingTime(marking: number) {
    if ($videoPlayer.current)
      $videoPlayer.current.currentTime = marking;
  }

  return {
    playerState,
    toggleVideoPlay,
    toggleVideoPause,
    toggleVideoProgress,
    toggleFullscreen,
    toggleChangeProgress,
    toggleFiveSeconds,
    toggleVolume,
    toggleRate,
    toggleMarkingTime,
  };
}

export const VideoPlayer: React.FC<IVideoPlayer> = ({
  marking,
  setMarking,
  modalIsOpen,
  isBegining,
  videoUrl,
}) => {
  const $videoPlayer = useRef<any>(null);
  const [controls, setControlls] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadAgain, setloadAgain] = useState(false);
  const [hearingRecordItem, setHearingRecordItem] = useState(false);

  const query = useQuery();
  const hearingId = query.get("hearingId");

  const getHearingVideo = async () => {
    if (!loadAgain) {
      setLoading(true);

      if (hearingId) {
        const { data, status } = await HearingService.getHearingById(
          Number(hearingId)
        );

        if (status && data && Array.isArray(data.hearingInProgress)) {
          const hearingRecord = data?.hearingInProgress?.find((x: any) => x.type === 1);

          if (hearingRecord) {
            setMarking({ ...marking, hearingRecord });
            setHearingRecordItem(hearingRecord);
          }
        } else {
          console.error("Erro: `data.hearingInProgress` não é um array ou está ausente.", data);
          setLoading(false);
        }

        if (!status) {
          setLoading(false);
        }
      }
    }
  };

  const canPlayVideo = () => {
    setLoading(false);
    setloadAgain(true);
  };

  const toggleFullScreenRequest = () => {
    setControlls(true);
    toggleFullscreen();
  };

  useEffect(() => {
    (async () => {
      await getHearingVideo();
    })();

    document.addEventListener("keydown", () => setControlls(false), false);
    document.addEventListener("fullscreenchange", () => setControlls(false));
  }, []);

  // offset
  useEffect(() => {
    if (isBegining) {
      setMarking({
        toggleMarkingTime,
        toggleRate,
        toggleVideoPlay,
        playerState: {
          ...playerState,
          offset1: isBegining
            ? playerState.currentTime
            : marking?.playerState.offset1,
          offset2: !isBegining ? playerState.currentTime : "00:00",
        },
        hearingRecord: hearingRecordItem,
      });
    }
  }, [isBegining]);

  //=================================SHORTCUTS==================================

  // eslint-disable-next-line prefer-const
  let startPause: boolean = true;
  const listShortcuts: Array<shortcut> = [];

  const handleKeyPress = useCallback(
    (event: any) => {
      if (event.ctrlKey === true) {
        event.preventDefault();
        const firstShortcut = "Ctrl";
        if (event.key !== event.ctrlKey) {
          const secondShortcut = event.code === "Space" ? "Espaço" : event.key;
          const findSC = listShortcuts?.find(
            (x) => x.shortcut === firstShortcut + "+" + secondShortcut
          );

          if (findSC) {
            executerShortcut(findSC.method);
          }
        }
      } else if (event.shiftKey === true && !modalIsOpen) {
        event.preventDefault();
        const firstShortcut = "Shift";
        if (event.key !== event.shiftKey) {
          const secondShortcut = event.code === "Space" ? "Espaço" : event.key;
          const findSC = listShortcuts?.find(
            (x) => x.shortcut === firstShortcut + "+" + secondShortcut
          );
          if (findSC) {
            executerShortcut(findSC.method);
          }
        }
      } else if (event.altKey === true) {
        event.preventDefault();
        const firstShortcut = "Alt";
        if (event.key !== event.altKey) {
          event.preventDefault();
          const secondShortcut = event.code === "Space" ? "Espaço" : event.key;
          const findSC = listShortcuts?.find(
            (x) => x.shortcut === firstShortcut + "+" + secondShortcut
          );
          event.preventDefault();
          if (findSC) {
            executerShortcut(findSC.method);
          }
        }
      } else {
        if (!modalIsOpen) {
          const secondShortcut = event.code === "Space" ? "Espaço" : event.key;
          const findSC = listShortcuts?.find(
            (x) => x.shortcut === secondShortcut
          );

          if (findSC) {
            executerShortcut(findSC.method);
          }
        }
      }
    },
    [modalIsOpen]
  );

  useEffect(() => {
    handleAddShortCuts(listShortcuts);

    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress, startPause]);

  function executerShortcut(method: string) {
    if (method === "GlobalPlayStop") {
      handlePlayStop();
    } else if (method === "GlobalActionNextTime") {
      handleActionNextTime(toggleFiveSeconds);
    } else if (method === "GlobalActionBackTime") {
      handleActionBackTime(toggleFiveSeconds);
    } else if (method === "GlobalSlowSpeed") {
      handleSlowSpeed($videoPlayer, toggleRate);
    } else if (method === "GlobalFastSpeed") {
      handleFastSpeed($videoPlayer, toggleRate);
    }
    return;
  }

  const handlePlayStop = () => {
    if (startPause) {
      toggleVideoPlay();
    } else {
      toggleVideoPause();
    }
    startPause = !startPause;
  };

  //================================================================================

  const {
    playerState,
    toggleVideoProgress,
    toggleVideoPlay,
    toggleVideoPause,
    toggleFullscreen,
    toggleChangeProgress,
    toggleMarkingTime,
    toggleFiveSeconds,
    toggleVolume,
    toggleRate,
  } = usePlayerState($videoPlayer);

  useEffect(() => {
    if (!playerState.playing) {
      setMarking({
        toggleMarkingTime,
        toggleRate,
        toggleVideoPlay,
        playerState: {
          ...playerState,
          offset1: isBegining
            ? playerState.currentTime
            : marking?.playerState.offset1,
          offset2: !isBegining ? playerState.currentTime : "00:00",
        },
        hearingRecord: hearingRecordItem,
      });
    }
  }, [
    $videoPlayer,
    playerState,
    playerState.duration,
    videoUrl,
    hearingRecordItem,
  ]);

  return (
    <Container>
      <VideoContainer>
        {videoUrl && (
          <VideoPlayerComponent
            ref={$videoPlayer}
            onCanPlay={canPlayVideo}
            onTimeUpdate={() => {
              const currentPorcetage =
                ($videoPlayer.current.currentTime /
                  $videoPlayer.current.duration) *
                100;
              toggleVideoProgress(
                $videoPlayer.current.currentTime,
                currentPorcetage
              );
            }}
            controls={controls}
            controlsList="nodownload"
            onContextMenu={(event) => event.preventDefault()}
            preload="auto"
          >
            <source src={videoUrl} type="video/mp4" />
          </VideoPlayerComponent>
        )}
        <PlayerControl
          disabled={loading}
          playerState={playerState}
          toggleChangeProgress={toggleChangeProgress}
          toggleVideoPlay={toggleVideoPlay}
          toggleFullscreen={toggleFullScreenRequest}
          toggleFiveSeconds={toggleFiveSeconds}
          toggleVolume={toggleVolume}
          toggleRate={toggleRate}
        />
        {videoUrl && loading ? (
          <LoadingContainer>
            <ReactLoading type="spin" color="#fff" width={100} height={100} />
            <VideoText>Carregando...</VideoText>
          </LoadingContainer>
        ) : (
          !videoUrl && <VideoText>Nenhum video para exibir</VideoText>
        )}
      </VideoContainer>
    </Container>
  );
};
