type statusType = {
  status: string;
  type: "waiting" | "warning" | "success" | "error";
};

export function status(code: string): statusType {
  switch (code) {
    case "A":
      return {
        status: "Agendado",
        type: "waiting",
      };
    case "I":
      return {
        status: "Agendado",
        type: "waiting",
      };
    case "G":
      return {
        status: "Em andamento",
        type: "warning",
      };
    case "Q":
      return {
        status: "Publicando",
        type: "success",
      };
    case "P":
      return {
        status: "Publicada",
        type: "success",
      };
    case "C":
      return {
        status: "Cancelada",
        type: "error",
      };
    case "D":
      return {
        status: "Downloading",
        type: "warning",
      };
    case "U":
      return {
        status: "Uploading",
        type: "warning",
      };
    case "S":
      return {
        status: "Processando",
        type: "warning",
      };
    case "E":
      return {
        status: "Encerrada",
        type: "success",
      };
    case "L":
      return {
        status: "Notificando",
        type: "warning",
      };
    case "N":
      return {
        status: "Notificado",
        type: "success",
      };
    case "X":
      return {
        status: "Erro notificação",
        type: "error",
      };
    default:
      return {
        status: "Invalido",
        type: "error",
      };
  }
}

export function statusRecorded(code: number): statusType {

  // não mostrar processando 10, 11, 14, 15
  switch (code) {
    case 10:
      return {
        status: "Agendado",
        type: "waiting",
      };
    case 11:
      return {
        status: "Iniciando",
        type: "warning",
      };
    case 12:
      return {
        status: "Em andamento",
        type: "warning",
      };
    case 13:
      return {
        status: "Finalizado",
        type: "success",
      };
    case 14:
      return {
        status: "Erro",
        type: "error",
      };
    case 15:
      return {
        status: "Cancelado",
        type: "error",
      };
    case 16:
      return {
        status: "Processando",
        type: "warning",
      };
    case 17:
      return {
        status: "Downloading",
        type: "warning",
      };
    case 18:
      return {
        status: "Uploading",
        type: "warning",
      };
    case 19:
      return {
        status: "Proc. Junção",
        type: "warning",
      };
    case 20:
      return {
        status: "Unificado",
        type: "warning",
      };
    case 21:
      return {
        status: "Erro na Junção",
        type: "warning",
      };
    case -50:
      return {
        status: "Process test",
        type: "warning",
      };
    default:
      return {
        status: "Agendado",
        type: "waiting",
      };
  }
}


export function ticksToTime(ticks: number) {
  const totalSeconds = Math.floor(ticks / 10000000);
  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = totalSeconds % 60;
  const leftoverTicks = ticks % 10000000;
  if (leftoverTicks >= 6000000) {
    seconds++;
  }
  if (seconds >= 60) {
    seconds = 0;
    minutes++;
  }
  if (minutes >= 60) {
    minutes = 0;
    hours++;
  }
  return (
    hours.toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0")
  );
}
