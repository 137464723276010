/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModalDefault } from "@kentarepo/kcf-assets";
import { LogOperation } from "../../../utils/entities/logOperation";
import { Axios, AxiosInstance } from "axios";
import { ToastMessage, useToast } from "../../../hooks/toast";

import {
  IFilterParameters,
  IHearingFileBody,
  IHearingFileDownloads,
  audiencesServices,
  hearingFileService,
} from "@kentarepo/kcf-services";

import {
  CreateRecord,
  IAudience,
  IOptionsFilterStatus,
} from "@kentarepo/kcf-templates";
import { hearingFileService as hearingFileServiceLocal } from "../../../services/hearingFile";
import { IntegrationService } from "../../../services/integrations";

import * as KentaTemplates from "@kentarepo/kcf-templates";

const getListDownloads = async (hearingId: number) => {
  if (hearingId !== undefined) {
    const list =
      await hearingFileServiceLocal.getAllDownloadsWithoutUrlByHearingId(
        hearingId
      );

    if (list.status === 200) {
      const listAux = list.data.data;
      return listAux as Array<IHearingFileDownloads>;
    }
  }

  return [];
};

export const handleItemClicked = async (
  element: IHearingFileDownloads,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    setLoading(true);
    const response = await hearingFileServiceLocal.downloadHearingFileById(
      element.hearingFileId
    );

    if (response.success) {
      const { blob, fileName } = response.body;

      try {
        // Validar se blob é um objeto Blob válido
        if (!(blob instanceof Blob)) {
          throw new Error("O objeto retornado não é um Blob válido.");
        }

        // Criar URL para o Blob
        const url = window.URL.createObjectURL(blob);

        setLoading(false);
        // Simular o download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        // Limpeza
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Erro ao processar o download:", error);
      }
    } else {
      console.error("Erro ao baixar o arquivo.");
    }
  } catch (error) {
    console.error("Erro ao baixar o arquivo:", error);
  }
};

export const handleOpenDrawerDownloads = async (
  hearingId: number,
  setOpenDownloads: React.Dispatch<React.SetStateAction<boolean>>,
  setListDownloads: React.Dispatch<
    React.SetStateAction<IHearingFileDownloads[]>
  >,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  exportPermission: boolean | undefined
) => {
  setOpenDownloads(true);
  setLoading(true);
  try {
    if (exportPermission) {
      const response = await getListDownloads(hearingId);
      setListDownloads(response);
    } else {
      setListDownloads([]);
    }
    setLoading(false);
  } finally {
    setLoading(false);
  }
};

export const hearingDelete = async (
  api: Axios,
  hearingId: number,
  listRecord: any,
  setListRecord: React.Dispatch<any>
) => {
  const hearingFileServiceApi = hearingFileService(api);
  const { data, status } = await hearingFileServiceApi.deleteById(hearingId);
  await LogOperation.postLogOperation("HearingFile", 3, 3);
  if (status === 200) {
    const newHearingScheduled = listRecord.hearingScheduled.filter(
      (x: any) => x?.id !== hearingId
    );
    setListRecord({ ...listRecord, hearingScheduled: newHearingScheduled });
  }
  return { data, status };
};

interface IShowModalDeleteAlert {
  api: Axios;
  hearingId: number;
  setHearingFileIdToDelete: React.Dispatch<React.SetStateAction<number>>;
  OpenModalConfirmDelete: boolean;
  setOpenModalConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>;
  listRecord: any;
  setListRecord: React.Dispatch<any>;
}

export const ShowModalDeleteAlert: React.FC<IShowModalDeleteAlert> = ({
  api,
  hearingId,
  setHearingFileIdToDelete,
  OpenModalConfirmDelete,
  setOpenModalConfirmDelete,
  listRecord,
  setListRecord,
}) => {
  const { addToast } = useToast();
  return (
    <ModalDefault
      openModal={OpenModalConfirmDelete}
      setOpenModal={setOpenModalConfirmDelete}
      title={"Excluir audiência"}
      bodyMessageTitle={"Deseja excluir a audiência agendada?"}
      bodyMessageSubTitle={"Esta ação não poderá ser desfeita"}
      textConfirm={"EXCLUIR"}
      onClickConfirm={() =>
        handleResultDeleteHearingFile(
          api,
          true,
          hearingId,
          setHearingFileIdToDelete,
          setOpenModalConfirmDelete,
          listRecord,
          setListRecord,
          addToast
        )
      }
      textCancel={"CANCELAR"}
      onClickCancel={() =>
        handleResultDeleteHearingFile(
          api,
          false,
          hearingId,
          setHearingFileIdToDelete,
          setOpenModalConfirmDelete,
          listRecord,
          setListRecord,
          addToast
        )
      }
      bodyMessageMarginBetweenTitleAndSubTitle="30px"
      bodyMessageMarginTop="30px"
    />
  );
};

const handleResultDeleteHearingFile = async (
  api: Axios,
  confirmed: boolean,
  hearingId: number,
  setHearingFileIdToDelete: React.Dispatch<React.SetStateAction<number>>,
  setOpenModalConfirmDelete: React.Dispatch<React.SetStateAction<boolean>>,
  listRecord: any,
  setListRecord: React.Dispatch<any>,
  addToast: (messages: Omit<ToastMessage, "id">) => void
) => {
  if (confirmed) {
    const { data, status } = await hearingDelete(
      api,
      hearingId,
      listRecord,
      setListRecord
    );

    if (status !== 200) {
      addToast({
        type: "error",
        title: "Audiência agendada",
        description: data.title,
      });
    } else {
      addToast({
        type: "success",
        title: "Audiência agendada",
        description: data.message,
      });
    }
    setOpenModalConfirmDelete(false);
    return;
  }
  setOpenModalConfirmDelete(false);
  setHearingFileIdToDelete(0);
};

interface ICreateRecordEdit {
  api: AxiosInstance;
  openEditRecord: boolean;
  setOpenEditRecord: React.Dispatch<React.SetStateAction<boolean>>;
  hearingFileToEdit: any;
  date: string | null | undefined;
  setDate: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  time: string | undefined;
  setTime: React.Dispatch<React.SetStateAction<string | undefined>>;
  setRecordEditedReturn: React.Dispatch<
    React.SetStateAction<IHearingFileBody | undefined>
  >;
}

export const CreateRecordEdit: React.FC<ICreateRecordEdit> = ({
  api,
  openEditRecord,
  setOpenEditRecord,
  hearingFileToEdit,
  date,
  setDate,
  time,
  setTime,
  setRecordEditedReturn,
}) => {
  const { addToast } = useToast();
  return (
    <CreateRecord
      title={"Gravações"}
      api={api}
      isEdit={true}
      addToast={addToast}
      setClose={() => setOpenEditRecord(false)}
      isOpen={openEditRecord}
      recordToEdit={hearingFileToEdit}
      date={date as any}
      setDate={setDate}
      time={time}
      setTime={setTime}
      setRecordEditedReturn={setRecordEditedReturn}
    />
  );
};

export const updateSchaduledList = (
  recordEditedReturn: IHearingFileBody,
  listRecord: any,
  setListRecord: React.Dispatch<any>,
  addToast: (messages: Omit<ToastMessage, "id">) => void
) => {
  const listScheduledAux = listRecord.hearingScheduled;

  const indexItem = listScheduledAux.findIndex(
    (item: any) => item?.id === recordEditedReturn?.id
  );

  if (indexItem !== -1) {
    listScheduledAux[indexItem] = recordEditedReturn;
  } else {
    addToast({
      title: "Erro",
      description: "Erro ao atualizar as gravações agendadas",
      type: "error",
    });
    return;
  }
  setListRecord({ ...listRecord, hearingScheduled: listScheduledAux });
};

export const filterSearch = async (
  api: AxiosInstance,
  placeId: number,
  hearingNumber: string,
  startDate: string | null | undefined,
  finishDate: string | null | undefined,
  valueOptionDropDown: IOptionsFilterStatus,
  activeFilter: boolean,
  setIsFilter: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenHearingFilter: React.Dispatch<React.SetStateAction<boolean>>,
  setAudiences: React.Dispatch<React.SetStateAction<IAudience[]>>,
  setAudience: React.Dispatch<any>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  addToast: (messages: Omit<ToastMessage, "id">) => void,
  setStartDateError: React.Dispatch<React.SetStateAction<boolean>>,
  setFinishDateError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  let startDateAux: string | undefined = "";
  let finishDateAux: string | undefined = "";

  if (startDate === null || startDate === "" || startDate === "Invalid Date") {
    startDateAux = undefined;
  } else {
    startDateAux = `${startDate}`;
  }

  if (
    finishDate === null ||
    finishDate === "" ||
    finishDate === "Invalid Date"
  ) {
    finishDateAux = undefined;
  } else {
    finishDateAux = `${finishDate}`;
  }

  if (startDateAux === undefined && finishDateAux !== undefined) {
    addToast({
      title: "Aviso",
      description: "Preencha ambas datas para pesquisar um período",
      type: "warning",
    });
    setStartDateError(true);
    return;
  } else if (startDateAux !== undefined && finishDateAux === undefined) {
    addToast({
      title: "Aviso",
      description: "Preencha ambas datas para pesquisar um período",
      type: "warning",
    });
    setFinishDateError(true);
    return;
  }

  if (startDateAux !== undefined && finishDateAux !== undefined) {
    if (new Date(startDateAux) > new Date(finishDateAux)) {
      addToast({
        title: "Aviso",
        description: "Período inválido",
        type: "warning",
      });
      setStartDateError(true);
      setFinishDateError(true);
      return;
    }
  }

  setLoading(true);

  const params: IFilterParameters = {
    placeId: placeId,
    procedureNumber: hearingNumber === "" ? undefined : hearingNumber,
    startDate: startDateAux,
    finishDate: finishDateAux,
    status: valueOptionDropDown?.value,
    active: activeFilter,
  };

  const audienceRecordServiceApi = audiencesServices(api);
  const response = await audienceRecordServiceApi.getAllByFilters(params);

  //só para a cor do botão não ficar laranja quando só tem filtro de ativo
  if (
    (params.procedureNumber === undefined || params.procedureNumber === "") &&
    params.startDate === undefined &&
    params.finishDate === undefined &&
    params.status === undefined &&
    params.active
  ) {
    setIsFilter(false);
  } else {
    setIsFilter(true);
  }

  if (!response.success) {
    setAudiences([]);
  } else {
    setAudiences(response.data as Array<any>);
  }

  setStartDateError(false);
  setFinishDateError(false);
  setAudience(null);
  setLoading(false);

  setOpenHearingFilter(false);
};

export const clearAudienceFilter = async (
  api: AxiosInstance,
  placeId: number,
  clearDatesFilter: number,
  setHearingNumber: React.Dispatch<React.SetStateAction<string>>,
  setStartDate: React.Dispatch<React.SetStateAction<string | null | undefined>>,
  setFinishDate: React.Dispatch<
    React.SetStateAction<string | null | undefined>
  >,
  setActiveFilter: React.Dispatch<React.SetStateAction<boolean>>,
  setValueOptionDropDown: React.Dispatch<any>,
  setClearDatesFilter: React.Dispatch<React.SetStateAction<number>>,
  setAudiences: React.Dispatch<React.SetStateAction<IAudience[]>>,
  setIsFilter: React.Dispatch<React.SetStateAction<boolean>>,
  setAudience: React.Dispatch<any>,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setStartDateError: React.Dispatch<React.SetStateAction<boolean>>,
  setFinishDateError: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setLoading(true);
  setHearingNumber("");
  setStartDate(null);
  setFinishDate(null);
  setValueOptionDropDown(undefined);
  setActiveFilter(true);
  setClearDatesFilter(clearDatesFilter + 1);
  setIsFilter(false);
  setStartDateError(false);
  setFinishDateError(false);

  const params: IFilterParameters = {
    placeId: placeId,
    procedureNumber: undefined,
    startDate: undefined,
    finishDate: undefined,
    status: undefined,
    active: true,
  };

  const audienceRecordServiceApi = audiencesServices(api);
  const response = await audienceRecordServiceApi.getAllByFilters(params);
  setAudiences(response.data as Array<any>);
  setAudience(null);
  setLoading(false);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getFirstRecord = (listRecord: any) => {
  if (listRecord) {
    return listRecord.hearingInProgress.find((item: any) => item.status === 13);
  }
  return null;
};

export const checkInputsFilled = async (
  addToast: (messages: Omit<ToastMessage, "id">) => void,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,  
  inputValues: { [key: string]: string }
) => {
  const allFilled = () => {
    for (const key in inputValues) {
      if (!inputValues[key]) {
        return false;
      }
    }
    return true;
  };
  if (allFilled()) {
    try {
      const [, firstValue] = Object.entries(inputValues)[0];

      const hearingIdIsNumber = Number(firstValue);
      if (isNaN(hearingIdIsNumber)) {
        addToast({
          type: "warning",
          title: "Notificar",
          description: "Por favor preencha os campos obrigatórios",
        });
        return false;
      } else {
        setLoading(true);
        const hearingId = parseInt(firstValue);
        const response = await IntegrationService.notify(hearingId);        

        if (response.status === 200) {
          addToast({
            type: "success",
            title: "Notificar",
            description: response.data.message,
          });         
          return true;
        } else {
          addToast({
            type: "error",
            title: "Notificar",
            description: response.data.message,
          });
          return false;
        }
      }
    } finally {
      setLoading(false);
    }
  } else {
    addToast({
      type: "warning",
      title: "Notificar",
      description: "Por favor preencha os campos obrigatórios",
    });
    return false;
  }
};

export const updateStatusHearing = (
  hearingIdToUpdate: any,
  hearingList: KentaTemplates.IAudience[],
  newStatus: string,
  setAudiences: React.Dispatch<
    React.SetStateAction<KentaTemplates.IAudience[]>
  >,
  listRecord: any,
  setListRecord: React.Dispatch<any>
) => {

  const newList = hearingList.map((item) =>
    item.id === hearingIdToUpdate ? { ...item, status: newStatus } : item
  );

  setAudiences(newList);
  setListRecord(listRecord);
};
